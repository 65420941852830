import React, {Component} from 'react';

import {
  Button,
  Row,
  Card,
  Col } from 'react-bootstrap';

import CustomerDetails from './CustomerDetailsComponent'
//import API from 'api-class';
import API from './api/API.js';
const myApi = new API();

export default class Customers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedCustomer: 1
      //selectedCustomer: false
    }
  }

  //function which is called the first time the component loads
  componentDidMount() {
    this.getCustomerData();
    //this._apiTest();
  }

  getCustomerData () {
    myApi.createEntity({ name : 'customers' })
    myApi.endpoints.customers.getAll()
      .then(({ data }) => this.setState({customerList: data}))
  }
  /**

  getCustomerData() {
    axios.post('https://api2.application-lte.domains/api/customers/?lala=lolo').then(response => {
      this.setState({customerList: response})
    })
  };

  _getCustomerData() {
    const apiUrl = 'https://api2.application-lte.domains/api/customers/index.php';
    const _this = this;
    fetch(apiUrl, {
      method: 'GET'
    })
      .then((resp) => resp.json()) // Transform the data into json
      .then(function(response) {
        _this.setState({customerList: response})
      })
  }


   **/

  render() {
    if (!this.state.customerList)
      return (<p>Loading data</p>)

    return (<Row className="addmargin">
      <Col>
        {
          this.state.customerList.map(customer => <Card key={customer.name} className="centeralign">
            <Card.Body>
              <Card.Title>{customer.name}</Card.Title>
              <p>{customer.email}</p>
              <p>{customer.phone}</p>
              <Button onClick={() => this.setState({selectedCustomer: customer.id})}>
                Click to View Details
              </Button>

            </Card.Body>
          </Card>)
        }
      </Col>

      <Col className="col-md-6">
        <CustomerDetails val={this.state.selectedCustomer}/>
      </Col>
    </Row>)
  }

}
