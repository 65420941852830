import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';

import HomeComponent from './HomeComponent';
import AboutComponent from './AboutComponent';
import AdminComponent from './AdminComponent';
import HeaderComponent from './HeaderComponent';
import Customers from './CustomersComponent';



class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <HeaderComponent></HeaderComponent>
            <Route exact path='/' component={HomeComponent}></Route>
            <Route exact path='/about' component={AboutComponent}></Route>
            <Route exact path='/admin' component={AdminComponent}></Route>
            <Route exact path='/customers' component={Customers}></Route>
          </div>
        </Router>

        {/*
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
        */}

      </div>
    );
  }
}

export default App;
