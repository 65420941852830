import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap';

import logo from './logo.svg';


function HeaderComponent (){
  return (
    <>
    <Navbar fixed="top"  bg="dark" variant="dark">
      <Link to="/" className="App-link">
      <Navbar.Brand>
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        {' ApplicationLTE Reactor'}
      </Navbar.Brand>
      </Link>
      <Nav className="mr-auto">
        <Link to="/" className="nav-link"> Home </Link>
        <Link to="/about" className="nav-link"> About </Link>
        <Link to="/admin" className="nav-link"> Admin </Link>
        <Link to="/customers" className="nav-link"> Customers </Link>
      </Nav>
    </Navbar>
      {/*
        <ul>
        <li>
        <Link to="/" className="App-link"> Home </Link>
        </li>
        <li>
        <Link to="/about" className="App-link"> About </Link>
        </li>
        <li>
        <Link to="/team" className="App-link"> Team </Link>
        </li>
        <li>
        <Link to="/customers" className="App-link"> customers </Link>
        </li>
        </ul>
      */}
    </>
  )
}

export default HeaderComponent;
